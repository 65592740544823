import React, { useState } from "react"

import Layout from "~/components/common/Layout"
import pages, { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { InfoboxDetailed } from "~/components/page/Infobox"
import { Box } from "reflexbox"
import { Text } from "~/components/common/Typography"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import HeroVideo from "~/components/page/HeroVideo"
import Copyright from "~/components/common/Copyright"
import { Accordion } from "~/components/common/Accordion"
import Source from "~/components/common/Source"

const Page = ({ myPage = 5, mySubpage = 2 }) => {
  const data = useStaticQuery(graphql`
      query {
          hero: file(name: { eq: "6.3HERO" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic1: file(name: { eq: "ADIDAS_CurriculumWebsite_Illos6.4.1" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic2: file(name: { eq: "ADIDAS_CurriculumWebsite_Illos6.4.2" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic3: file(name: { eq: "ADIDAS_CurriculumWebsite_Illos6.4.3" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const { hero, graphic1, graphic2, graphic3 } = data

  const isOpenInitialState = {}
  pages.forEach((_, index) => (isOpenInitialState[index] = false))
  const [isOpen, setIsOpen] = useState(isOpenInitialState)

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="Physical health helps improve girls’ mental health."
        image={hero}
        bg={"white"}
      />

      <InfoboxDetailed
        headerText={"How it works"}
        contentMaxWidth={"mediumContent"}
      >
        <Box mt={6}>
          <Accordion
            boldHeader
            heading={"Reducing symptoms of depression and trauma"}
            onToggle={() =>
              setIsOpen(s => {
                return Object.assign({}, s, { [0]: !s[0] })
              })
            }
            isOpen={isOpen[0]}
          >
            <Box textAlign={"left"}>
              <Box mt={5}>
                <Img fluid={graphic1.childImageSharp.fluid}/>
              </Box>
              <Text>
                A study from Translational Psychiatry found that one-third of
                teen girls experience an episode of depression. That's almost
                three times the rate of boys<Source>53</Source>.The occurrence
                of anxiety-related disorders in teennagers has also risen in the
                last decade, particularly in girls.<Source>54</Source> “In
                results from the National Survey of Drug Use and Health between
                2009 and 2014, 36.1 percent of girls reported depression,
                compared to 13.6 percent of boys who had their first onset of
                depression.”<Source>55</Source> However, participation in sports
                and regular physical activity are linked to decreased stress and
                depression, since exercise naturally lifts moods.
                <Source>56</Source>
              </Text>
              <Text>
                As part of a team, girls make friends who will support each
                other on and off the field. As a result, girls are less likely
                to feel lonely or bored.
              </Text>
              <Text>
                Girls who have experienced trauma may also find opportunities
                for recovery in team sports. Sports may provide an important
                structure and a place to connect with mentors and friends and a
                safe space that allows girls to escape the struggles they face
                in everyday life.<Source>57</Source>
              </Text>
            </Box>
          </Accordion>
        </Box>
        <Box mt={4}>
          <Accordion
            boldHeader
            heading={"Increasing self-esteem and self-confidence"}
            onToggle={() =>
              setIsOpen(s => {
                return Object.assign({}, s, { [1]: !s[1] })
              })
            }
            isOpen={isOpen[1]}
          >
            <Box textAlign={"left"}>
              <Box mt={5}>
                <Img fluid={graphic2.childImageSharp.fluid}/>
              </Box>
              <Text>
                As girls move from elementary school to high school, their
                self-esteem levels drop at a rate three times that of boys.
                <Source>58</Source>
              </Text>
              <Text>
                Only 29% of high school girls reported being “happy the way
                [they are]”<Source>59</Source>
              </Text>
              <Text>
                A study conducted by the Melpomene Institute found that “a
                girl’s low self-esteem does not disappear with maturity. Girls
                with low self-esteem often grow to be women with low
                self-esteem. Low levels of self-esteem are linked to increased
                rates of depression, substance abuse, suicide, and eating
                disorders in both adolescents and adults.”<Source>60</Source>
              </Text>
              <Text>
                However, girls who play sports worry less about what they look
                like and whether or not they are attractive.<Source>61</Source>{" "}
                They also perceive their own academic abilities to be higher.
                <Source>62</Source>
              </Text>
              <Text>
                Coaches can influence how girls cultivate self confidence, high
                self esteem and healthy body image. Sports also help girls
                understand their own ownership of their bodies.
                <Source>63</Source>
              </Text>
            </Box>
          </Accordion>
        </Box>
        <Box mt={4}>
          <Accordion
            boldHeader
            heading={"Lowering the risk of suicide "}
            onToggle={() =>
              setIsOpen(s => {
                return Object.assign({}, s, { [2]: !s[2] })
              })
            }
            isOpen={isOpen[2]}
          >
            <Box textAlign={"left"}>
              <Box mt={5}>
                <Img fluid={graphic3.childImageSharp.fluid}/>
              </Box>
              <Text>
                Teenage girls who participate in sports are less likely to
                consider, plan, or attempt suicide than girls who are not
                athletes.<Source>64</Source>
              </Text>
            </Box>
          </Accordion>
        </Box>
      </InfoboxDetailed>

      <Copyright/>
    </Layout>
  )
}

export default withProgress(Page, 5, 2)
